import React from 'react'
import {
    Box,
    Heading,
    Flex,
    Text,
    List,
    ListItem,
    useToken,
    useTheme,
    Grid,
    GridItem,
    OrderedList,
    // Button,
    // Modal,
    // ModalOverlay,
    // ModalContent,
    // ModalCloseButton,
    // ModalBody,
    // ModalFooter,
    // useDisclosure,
    keyframes,
} from '@chakra-ui/react'
import { StaticImage, GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'
import { Link } from '../components/Link'
import { PageBlock } from '../components/PageBlock'
import OrientierWordmarkLight from '../images/orientier_wordmark_light.svg'
import { DictionaryDefinition } from '../components/DictionaryDefinition'
import { NextLevelContours } from '../components/NextLevelContours'

const heroImageHeight = '1600px'

const ppfTestimonialImage = () => {
    const imgWidth = 500
    const imgHeight = 294

    return (
        <Box
            sx={{
                '.gatsby-image-wrapper': {
                    width: '100%',
                    aspectRatio: `${imgWidth}/${imgHeight}`,
                },
            }}
        >
            <StaticImage
                src="../images/matt_dixon_talking_about_orientier.jpg"
                alt="Matt Dixon of Purple Patch Fitness, talking about Orientier"
                placeholder="blurred"
            />
        </Box>
    )
}

const playTriangleIcon = () => (
    <StaticImage src="../images/play_triangle_icon.png" alt="Play" placeholder="blurred" layout="fixed" width={75} />
)

const whatWeDoCategories = ['Development', 'Design', 'Consulting', 'Innovation']

const whatWeDoItems = [
    'Custom website and web app development',
    'Content Management Systems (CMS) - Drupal, Wordpress, Contentful, Storyblok, etc. etc.',
    'Extending the capabilities of no-code/low-code website builders (Webflow, etc.)',
    'APIs and systems integration',
    'Open source software frameworks',
    'Web and graphic design',
    'UX and UI design',
    'Strategy',
    'Content architecture',
    'Data migrations',
    'Custom map interfaces, GIS integrations, etc.',
    'Content strategy/messaging',
    'CDNs and edge computing',
    'Serverless architectures',
    'Digital Asset Management',
    'Databases and alternative data stores',
    'Solutions architecture',
    'Search Engine Optimization',
    'Performance auditing and optimization',
    'Accessibility auditing and optimization',
    'Emerging technologies',
]

const ppfTestimonialVideoUrl = 'https://www.youtube.com/watch?v=t-veTaHMDK8'

const contactMethods = [
    {
        text: 'hello@orientier.com',
        url: 'mailto:hello@orientier.com',
    },
    {
        text: 'X/Twitter',
        url: 'https://x.com/OrientierCo',
    },
    {
        text: 'Instagram',
        url: 'https://www.instagram.com/orientierco',
    },
    {
        text: 'LinkedIn',
        url: 'https://www.linkedin.com/company/orientier',
    },
    {
        text: 'Facebook',
        url: 'https://www.facebook.com/OrientierCo',
    },
]

const now = new Date()
const currentYear = now.getFullYear()

const strokeDashArrayBase = 40
const strokeDashArraySm = 20
const strokeDashArrayLg = 13
const pathDashBase = keyframes`
  to {
    stroke-dashoffset: ${strokeDashArrayBase * 2}
  }
`
const pathDashSm = keyframes`
  to {
    stroke-dashoffset: ${strokeDashArraySm * 2}
  }
`
const pathDashLg = keyframes`
  to {
    stroke-dashoffset: ${strokeDashArrayLg * 2}
  }
`

export const Head = () => (
    <>
        <title>Orientier | Find the next level on your tech journey</title>
        <meta
            name="description"
            content="Orientier is a digital services agency near Boston, Massachusetts. We help businesses and organizations build lasting and dynamic web solutions."
        />
    </>
)

const IndexPage = ({ data }) => {
    const {
        constants: { LIST_UL_BULLETED, LIST_VARIANT_BULLETPROOF, LINK_BUTTON_OUTLINE, LINK_INHERIT },
    } = useTheme()
    const [oriGrayscale900, oriBlue600, oriBlue900, oriOrange500] = useToken('colors', [
        'oriGrayscale.900',
        'oriBlue.600',
        'oriBlue.900',
        'oriOrange.500',
    ])

    const heroImages = withArtDirection(getImage(data.heroImage), [
        {
            media: '(max-width: 768px)',
            image: getImage(data.heroImageMobile),
        },
    ])

    const heroImage = (
        <GatsbyImage
            image={heroImages}
            alt="Stars emerge in the sky above twin mountain peaks"
            placeholder="blurred"
            layout="fullWidth"
            loading="eager"
            style={{
                height: '100vh',
                width: '100vw',
                maxHeight: heroImageHeight,
            }}
        />
    )

    return (
        <>
            <Box as="header" bg="oriGrayscale.900" color="oriGrayscale.50">
                <Box className="heroLead" position="relative" height="100vh" width="100vw" maxHeight={heroImageHeight}>
                    {heroImage}
                    <Box
                        className="heroText"
                        position="absolute"
                        zIndex={2}
                        left={0}
                        top={0}
                        pt={{ base: 10, md: 16 }}
                        pr={{ base: 6, sm: 32, lg: 64 }}
                        pb={{ base: 16, sm: 64 }}
                        pl={{ base: 6, sm: 12, lg: 24, xl: 32, '2xl': 64 }}
                        maxWidth={{ base: '100%', sm: 'container.sm', lg: 'container.md', '2xl': 'container.lg' }}
                        bg={{
                            base: 'linear-gradient(to bottom, rgba(0,0,0,0.75) 0%, rgba(0,0,0,0.0) 100%)',
                            sm: 'radial-gradient(ellipse at top left, rgba(0,0,0,0.75) 0%, rgba(0,0,0,0.0) 70%)',
                        }}
                    >
                        <Heading as="h1" size="2xl" mb={4}>
                            Find the Next Level
                        </Heading>
                        <Text fontStyle="italic" lineHeight={7}>
                            Our team of creative technologists will accelerate your journey upward and onward.
                        </Text>
                    </Box>
                </Box>
                <Grid
                    className="heroMain"
                    position="relative"
                    mt={{ base: '-70vh', '2xl': '-60vh' }}
                    gridTemplateRows={{ base: '50vh 20vh 1fr', '2xl': '50vh 10vh 1fr' }}
                    gridTemplateColumns="1fr"
                >
                    <GridItem
                        gridRowStart={1}
                        gridRowEnd={3}
                        gridColumn={1}
                        bg={`linear-gradient(to top, ${oriGrayscale900} 0%, ${oriGrayscale900}eb 15%, rgba(0,0,0,0) 100%)`}
                    />
                    <GridItem gridRowStart={2} gridRowEnd={4} gridColumn={1} overflowX="hidden">
                        <Box
                            as="svg"
                            xmlns="http://www.w3.org/2000/svg"
                            width="90vw"
                            height="auto"
                            viewBox="0 0 1835.88 1033.54"
                            ml={{ base: '50vw', lg: '40vw', xl: '50vw' }}
                            sx={{
                                path: {
                                    fill: 'none',
                                    stroke: { base: 'url(#pathFadeMin)', lg: 'url(#pathFade)' },
                                    strokeWidth: '2px',
                                    strokeDasharray: {
                                        base: strokeDashArrayBase,
                                        sm: strokeDashArraySm,
                                        lg: strokeDashArrayLg,
                                    },
                                    strokeDashoffset: 0,
                                    animation: {
                                        base: `${pathDashBase} 2s linear infinite`,
                                        sm: `${pathDashSm} 2s linear infinite`,
                                        lg: `${pathDashLg} 2s linear infinite`,
                                    },
                                },
                            }}
                        >
                            <defs>
                                <linearGradient id="pathFade">
                                    <stop offset="0%" stopColor={oriOrange500} stopOpacity="0" />
                                    <stop offset="50%" stopColor={oriOrange500} />
                                    <stop offset="100%" stopColor={oriOrange500} />
                                </linearGradient>
                                <linearGradient id="pathFadeMin">
                                    <stop offset="0%" stopColor={oriOrange500} stopOpacity="0" />
                                    <stop offset="30%" stopColor={oriOrange500} />
                                    <stop offset="100%" stopColor={oriOrange500} />
                                </linearGradient>
                            </defs>
                            <path d="M.24,.97C345.44,86.84,148.95,215.26,371.56,214.14c222.61-1.12,416.48,33.11,405.88,144.33s-347.64,421.32,1058.26,674.09" />
                        </Box>
                    </GridItem>
                    <GridItem gridRow={3} gridColumn={1}>
                        <Flex
                            className="heroMainInner"
                            px={{ base: 4, sm: 8, md: 12 }}
                            pt={0}
                            pb={{ base: 12, md: 24 }}
                            maxWidth="container.xl"
                            mx="auto"
                            gap={{ base: 12, md: 24 }}
                            flexDirection={{ base: 'column', md: 'row' }}
                        >
                            <Box>
                                <Heading as="h2" size="xl" display="flex" gap={4} flexDirection="column">
                                    <Box as="span">Small Enough to Care.</Box>
                                    <Box as="span">Young Enough to Keep things Fresh.</Box>
                                    <Box as="span">Experienced Enough to Tackle Anything.</Box>
                                </Heading>
                                <Box py={12} pb={{ base: 12, md: 32 }} maxWidth="container.md">
                                    <Text>
                                        Whether you’re looking for a full-service digital agency to make your vision a
                                        reality, or you want to add expert resources to your team, Orientier can serve
                                        as a trusted partner.
                                    </Text>
                                    <Text>
                                        Our customers find that we engage on a deep level and truly seek to understand
                                        their goals, business model, pain points, requirements, hopes and dreams.
                                    </Text>
                                    <Text>
                                        We’ll get to know you, ask good questions, and move forward together as we
                                        implement solutions.
                                    </Text>
                                    <Text>Come join us. We look forward to building something great.</Text>
                                </Box>
                            </Box>
                            <Flex alignItems="flex-end" justifyContent="flex-end">
                                <img src={OrientierWordmarkLight} alt="Orientier logo" width="300px" />
                            </Flex>
                        </Flex>
                    </GridItem>
                </Grid>
            </Box>
            <Box as="main">
                <PageBlock heading="What we do" identifier="what-we-do">
                    <Flex
                        flexDirection={{ base: 'column', md: 'row' }}
                        gap={{ base: 12, lg: 20 }}
                        justifyContent="space-evenly"
                        alignItems="normal"
                    >
                        <Flex
                            flexDirection={{ base: 'row', md: 'column' }}
                            gap={4}
                            justifyContent="space-evenly"
                            flexWrap="wrap"
                            fontStyle="italic"
                            fontSize={{ base: '3xl', md: '4xl' }}
                        >
                            {whatWeDoCategories.map((category, index) => (
                                <Text key={index} px={4} textAlign="center">
                                    {category}
                                </Text>
                            ))}
                        </Flex>
                        <List as="ul" variant={LIST_UL_BULLETED} pl={{ base: 4, sm: 8, md: 0 }}>
                            {whatWeDoItems.map((item, index) => (
                                <ListItem key={index}>{item}</ListItem>
                            ))}
                        </List>
                    </Flex>
                </PageBlock>
                <PageBlock
                    heading={null}
                    identifier="ppf-testimonial"
                    bg={`linear-gradient(to bottom right, ${oriBlue900} 0%, ${oriBlue600} 100%)`}
                    color="oriGrayscale.50"
                    display="flex"
                    alignItems="center"
                >
                    <Flex
                        flexDirection={{ base: 'column', lg: 'row' }}
                        gap={{ base: 20, lg: 12 }}
                        justifyContent="space-evenly"
                        alignItems="center"
                    >
                        <Box p={{ base: 0, md: 6 }}>
                            <Box
                                as="blockquote"
                                position="relative"
                                fontSize="3xl"
                                px={{ base: 2, md: 12 }}
                                pt={{ base: 20, md: 12 }}
                                pb={{ base: 20, md: 32 }}
                                sx={{
                                    '&:before, &:after': {
                                        fontSize: '8em',
                                        fontStyle: 'italic',
                                        color: 'oriBlue.200',
                                        position: 'absolute',
                                    },
                                    '&:before': {
                                        content: '"“"',
                                        left: { base: 0, md: -12 },
                                        top: { base: -24, md: -20 },
                                    },
                                    '&:after': {
                                        content: '"”"',
                                        right: { base: 0, md: -4 },
                                        bottom: { base: -56, md: -36 },
                                    },
                                }}
                            >
                                <Text textAlign="right">
                                    We’ve become one of the more successful endurance coaching companies in the world,
                                    and Orientier have been key assets, almost becoming part of our team. They’ve helped
                                    us ask questions like ‘what could we do next?’ ‘How can we maximize the experience?’{' '}
                                    <br />
                                    <Box as="span" fontStyle="italic" fontWeight={600}>
                                        Orientier have really enabled us to accelerate.
                                    </Box>
                                </Text>
                            </Box>
                        </Box>
                        <Flex
                            flexDirection="column"
                            justifyContent="center"
                            gap={12}
                            maxWidth="500px"
                            minWidth={{ base: 0, md: '400px' }}
                        >
                            <Box position="relative">
                                {ppfTestimonialImage()}
                                <Link
                                    display="flex"
                                    variant={LINK_INHERIT}
                                    position="absolute"
                                    width="100%"
                                    height="100%"
                                    top={0}
                                    left={0}
                                    alignItems="center"
                                    justifyContent="center"
                                    transition="all 0.3s ease-in-out"
                                    _hover={{
                                        cursor: 'pointer',
                                        opacity: 0.8,
                                    }}
                                    destination={ppfTestimonialVideoUrl}
                                >
                                    {playTriangleIcon()}
                                </Link>
                            </Box>
                            <Box borderLeft="1px solid" borderLeftColor="oriBlue.200" pl={4}>
                                <Box as="span">Matt Dixon</Box> <br />
                                <Box as="span" fontStyle="italic">
                                    Founder and CEO of Purple Patch
                                </Box>
                            </Box>
                            <Flex justifyContent="center">
                                {/* <Button onClick={onVideoModalOpen}>Watch the Video</Button> */}
                                <Link destination={ppfTestimonialVideoUrl} variant={LINK_BUTTON_OUTLINE}>
                                    Watch the Video
                                </Link>
                            </Flex>

                            {/* <Modal isOpen={isVideoModalOpen} onClose={onVideoModalClose}> */}
                            {/*    <ModalOverlay /> */}
                            {/*    <ModalContent> */}
                            {/*        <ModalCloseButton /> */}
                            {/*        <ModalBody> */}
                            {/*            <Box position="relative" overflow="hidden" pt="56.25%"> */}
                            {/*                <Box */}
                            {/*                    as="iframe" */}
                            {/*                    width="640" */}
                            {/*                    height="360" */}
                            {/*                    _sx={{ */}
                            {/*                        position: 'absolute', */}
                            {/*                        top: '0', */}
                            {/*                        left: '0', */}
                            {/*                        width: '100%', */}
                            {/*                        height: '100%', */}
                            {/*                        border: '0', */}
                            {/*                    }} */}
                            {/*                    src="https://www.youtube.com/embed/t-veTaHMDK8?rel=0" */}
                            {/*                    frameBorder="0" */}
                            {/*                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" */}
                            {/*                    allowFullScreen */}
                            {/*                    title="Purple Patch: An Orientier Client Success Story" */}
                            {/*                /> */}
                            {/*            </Box> */}
                            {/*        </ModalBody> */}
                            {/*    </ModalContent> */}
                            {/* </Modal> */}
                        </Flex>
                    </Flex>
                </PageBlock>
                <PageBlock identifier="orientier_definition" variant="darkWithContours" minHeight={0}>
                    <Flex flexDirection="column" gap={16} height="100%" justifyContent="center">
                        <DictionaryDefinition word="Orienteer" type="noun" pronunciation="ȯr-ē-ən-ˈtir">
                            A person skilled with map and compass, who enjoys navigating unfamiliar terrain - so much so
                            that they do it for fun.
                        </DictionaryDefinition>
                        <DictionaryDefinition word="Orientier" type="noun" pronunciation="ȯr-ē-ən-ˈtir">
                            <OrderedList>
                                <ListItem>One who finds the next level and guides others as they do the same.</ListItem>
                                <ListItem>
                                    A team of creative technologists guiding people toward mutual success.
                                </ListItem>
                            </OrderedList>
                        </DictionaryDefinition>
                    </Flex>
                </PageBlock>
                <PageBlock heading="Let's Connect" identifier="connect">
                    <Flex
                        flexDirection={{ base: 'column', md: 'row' }}
                        gap={{ base: 12, md: 24 }}
                        justifyContent="space-evenly"
                    >
                        <Box>
                            <Text>
                                We are proudly headquartered in Lynn, Massachusetts, USA - a vibrant city just north of
                                Boston on the Atlantic coast.
                            </Text>
                            <Text>
                                We’ve partnered with a really cool organization called{' '}
                                <Link destination="https://beyondwalls.org">Beyond Walls</Link> and are building a fun,
                                accessible workspace in the heart of the city. Come pay us a visit!
                            </Text>
                            <Text fontWeight={700}>
                                <Link destination="https://goo.gl/maps/3MUdWjFAEkgARS2N7">
                                    Orientier <br />
                                    18 Mount Vernon Street <br />
                                    Lynn, MA 01901
                                </Link>
                            </Text>
                        </Box>
                        <List
                            as="ul"
                            variant={LIST_VARIANT_BULLETPROOF}
                            pl={{ base: 4, sm: 8, md: 10 }}
                            border="0px solid"
                            borderColor="oriBlue.200"
                            borderLeftWidth="1px"
                            display="flex"
                            flexDirection="column"
                            gap={6}
                        >
                            {contactMethods.map(({ text, url }, index) => (
                                <ListItem key={index} fontWeight={500}>
                                    <Link destination={url}>{text}</Link>
                                </ListItem>
                            ))}
                        </List>
                    </Flex>
                </PageBlock>
            </Box>
            <Box as="footer" bg="oriGrayscale.900" color="oriGrayscale.200" position="relative">
                <NextLevelContours shouldTransformForMobile={false} />
                <Text
                    position={{ base: 'relative', sm: 'absolute' }}
                    bottom={0}
                    right={0}
                    p={{ base: 4, sm: 10, lg: 12, xl: 20 }}
                    fontStyle="italic"
                    textAlign="right"
                >
                    Copyright {currentYear} Orientier, Inc.
                </Text>
            </Box>
        </>
    )
}

export default IndexPage

export const query = graphql`
    query pageQuery {
        heroImage: file(
            sourceInstanceName: { eq: "images" }
            relativePath: { eq: "orientier_find_the_next_level_hero.jpg" }
        ) {
            childImageSharp {
                gatsbyImageData
            }
        }
        heroImageMobile: file(
            sourceInstanceName: { eq: "images" }
            relativePath: { eq: "orientier_find_the_next_level_hero_mobile_portrait.jpg" }
        ) {
            childImageSharp {
                gatsbyImageData
            }
        }
    }
`
